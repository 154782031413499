import React from 'react';
import './../main.css';

class ComingSoonBackgroundComponent extends React.Component {
   render() {
      return (
        <div id="cs-bg" />
      );
   }
}

export default ComingSoonBackgroundComponent;
