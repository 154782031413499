import React from 'react'
import './../main.css'

class ComingSoonHeaderComponent extends React.Component {
    render() {
        return (
            <div id="cs-header">
                <img id="logo" src="logo.png"></img>
                <p>truemate.ai</p>
            </div>
        );
    }
}

export default ComingSoonHeaderComponent;
