import React from 'react'
import './../main.css'

class ComingSoonFooterComponent extends React.Component {
    render() {
        return (
            <div id="cs-footer">
                <p id="brand">2023 True Mate LLC. All Rights Reserved.</p>
                <div id="rbtns">
                    <p className="rbtns-item" onClick={this.onContactClick}>
						Contact Us
					</p>
                    <p className="rbtns-item" onClick={this.onPrivacyClick}>
                        Privacy Policy
                    </p>
                    <p className="rbtns-item" onClick={this.onTOSClick}>
                        Terms of Use
                    </p>
                </div>
            </div>
        );
    }

    onPrivacyClick() {
		let a = document.createElement('a');
		a.href = "https://drive.google.com/file/d/1au6JLA4u_pVCPyiJSaI_HlPl8tG3FVK6/view?usp=share_link";
		a.target = "_blank";
		a.click();
    }

    onTOSClick() {
		let a = document.createElement('a');
		a.href = "https://drive.google.com/file/d/1vbXACdZk1bUoVE_lSSHQlEV6e93uEhUu/view?usp=sharing";
		a.target = "_blank";
		a.click();
    }

	onContactClick() {
		window.location = 'mailto:contactus@truemate.ai'
	}
}

export default ComingSoonFooterComponent;
