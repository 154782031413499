import React from 'react';
import './../main.css';

import ComingSoonBackgroundComponent from './ComingSoonBackgroundComponent';
import ComingSoonHeaderComponent from './ComingSoonHeaderComponent';
import ComingSoonFooterComponent from './ComingSoonFooterComponent';

class ComingSoonPage extends React.Component {
   render() {
      return (
        <div id="main-container">
            <ComingSoonBackgroundComponent />
            <div id="cs-content">
                <ComingSoonHeaderComponent />
                <p id="cs-center-lbl" className="animate-charcter">Coming Soon...</p>
                <ComingSoonFooterComponent />
            </div>
        </div>
      );
   }
}

export default ComingSoonPage;
